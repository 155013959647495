import React from 'react';
import ABDMEnrollemtStepBase from './EnrollmentStepBase'

export default class ABDMAbhaAddressUpdate extends ABDMEnrollemtStepBase {

    constructor(props) {
        super(props);
        this.syncMessage();
    }

    state = {
        data : {
            abha_address: ''
        },
        message: '',
        error_message: '',
        errors: {}
    }

    setSelectedAbhaAddress = (abha_address) => {
        this.setState({
            data: {
                ...this.state.data,
                abha_address: abha_address
            }
        })
    }

    syncMessage = () => {
        this.state = {
            ...this.state,
            message: this.props.message[this.getComponentStep()] || "",
            error_message: this.props.error_message[this.getComponentStep()] || "",
        };
    }

    validateInput = () => {
        const abhaPattern = /^(?=[a-zA-Z])[a-zA-Z0-9.]{3,}[a-zA-Z0-9]$/;
        var is_valid = abhaPattern.test(this.state.data.abha_address);
        this.state.errors.abha_address = is_valid ? '' : 'Please enter a valid ABHA address';
    }

    handleResponse = (response) => {
        if(response.status === 200) {
            this.props.updateStep(5, response.message, '', response.profile);
        } else if(response.status === 500) {
            this.props.updateStep(this.getComponentStep(), '', response.message)
        }
    }

    getEndpointUrl = () => {
        return '/dashboard/abdm/enroll/update_abha_address'
    }

    getRequestData = () => {
        let data = this.state.data;
        data.req_id = this.props.getReqId()
        return data
    }

    getComponentStep = () => {
        return 4
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        this.setState({
            data:{
                ...this.state.data,
                [name]: value
            }
        });
    }

    render_internal = () => {
        return (
            <>
                <h1 className='mb-3'>Update ABHA Address</h1>

                {(this.state.message)?
                <div className='alert alert-success mb-3'>
                    {this.state.message}
                </div>:null}

                {(this.state.error_message)?
                <div className='alert alert-danger mb-3'>
                    {this.state.error_message}
                </div>:null}
                
                <div className="form-group">
                <label className="control-label required ds_form_labels" htmlFor="Language">ABHA Address</label>
                    <input id="abha_address" name="abha_address" type="text" placeholder="Enter preferred ABHA address" className="form-control mt-0 mb-3" onChange={this.handleChange} value={this.state.data.abha_address}/>
                    <p className='error_msg'>{this.state.errors.abha_address}</p>
                </div>
                <div className='mt-2 text-start mb-4'>
                <label className="control-label required ds_form_labels" htmlFor="Language">Suggestions</label>
                {this.props.shared_states[this.getComponentStep()].map((name) => (
                    <span
                        key={name}
                        className="btn p-0 pe-3"
                        onClick={() => this.setSelectedAbhaAddress(name)}
                        style={{textTransform:"none"}}
                    >
                        {name}
                    </span>
                ))}
                </div>

                <button className="btn btn-primary btn-block w-100 btn-block" onClick={this.submitRequest}>Update ABHA Address</button>
            </>
        )
    }
}