import React from 'react';
import ABDMEnrollemtStepBase from './EnrollmentStepBase'

export default class ABDMAdhaarEnrollment extends ABDMEnrollemtStepBase {

    state = {
        data : {
            document_id: '',
            language: 'ENGLISH',
            consent: '',
            otp_mode: 'AADHAAR'
        },
        error_message: '',
        errors: {},
        consent_text: 'I provide consent for sharing my aadhaar details and creation of ABHA Card.'
    }

    constructor(props) {
        super(props);
        this.syncMessage();
    }

    syncMessage = () => {
        this.state = {
            ...this.state,
            message: this.props.message[this.getComponentStep()] || "",
            error_message: this.props.error_message[this.getComponentStep()] || "",
        };
        if(this.state.error_message == "Invalid aadhaar card number") {
            this.state.errors.document_id = this.state.error_message;
            this.state.error_message = ""
        }
    }

    validateInput = () => {
        this.state.errors.document_id = this.state.data.document_id.length !== 12 ? 'Please enter your 12 digit aadhaar number' : '';
        this.state.errors.language = this.state.data.language.length === 0 ? 'Please select a language' : '';
        this.state.errors.consent = (this.state.data.consent.length === 0 || this.state.data.consent === false) ? 'Please provide your consent' : '';
    }

    handleResponse = (response) => {
        if(response.status === 200) {
            this.props.updateReqId(response.req_id);
            this.props.updateStep(2, response.message, '', this.state.data);
        } else if(response.status === 500) {
            this.props.updateStep(1, '', response.message);
        }
    }

    getEndpointUrl = () => {
        return '/dashboard/abdm/enroll/request_otp'
    }

    getRequestData = () => {
        return this.state.data
    }

    getComponentStep = () => {
        return 1
    }

    updateContentText = (language) => {
        if(language == "ENGLISH") {
            this.state.consent_text = "I provide consent for sharing my aadhaar details and creation of ABHA Card."
        } else if(language == "HINDI") {
            this.state.consent_text = "मैं अपना आधार विवरण साझा करने और ABHA कार्ड बनाने के लिए सहमति प्रदान करता हूं।"
        } else if(language == "KANNADA") {
            this.state.consent_text = "ನನ್ನ ಆಧಾರ್ ವಿವರಗಳನ್ನು ಹಂಚಿಕೊಳ್ಳಲು ಮತ್ತು ABHA ಕಾರ್ಡ್ ರಚನೆಗೆ ನಾನು ಸಮ್ಮತಿಯನ್ನು ನೀಡುತ್ತೇನೆ."
        }
    }

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        if(name == "language") {
            this.updateContentText(value);
        }
        this.setState({
            data:{
                ...this.state.data,
                [name]: value
            }
        });
    }

    render_internal = () => {
        return (
            <>
                <h1 className='mb-4'>Using Aadhaar Number</h1>

                {(this.state.error_message.length > 0)?
                <div className='alert alert-danger mb-3'>
                    {this.state.error_message}
                </div>
                :null}

                <div className="form-group mb-3">

                    <label className="control-label required ds_form_labels" htmlFor="Language">Aadhaar Number</label>
                    <input id="document_id" name="document_id" type="number" placeholder="Enter your Aadhaar Number" className="form-control mt-0" onChange={this.handleChange} />
                    <p className='error_msg'>{this.state.errors.document_id}</p>
                </div>

                <div className="form-group mb-4">
                    <label className="control-label required ds_form_labels" htmlFor="Language">Select language</label>
                    <select id="language" name="language" placeholder="Language" type="text" className="form-select mt-0" onChange={this.handleChange}>
                        <option value="ENGLISH">English</option>
                        <option value="HINDI">Hindi</option>
                        <option value="KANNADA">Kannada</option>
                    </select>
                    <p className='error_msg'>{this.state.errors.language}</p>
                </div>
                    
                <div className="form-group mb-3">
                    <div style={{background:"#f9f9f9",border:"1px solid #ddd",textAlign:"left",fontSize:"12px","padding":"10px",lineHeight:"18px"}}>
                        { this.state.consent_text }
                    </div>
                </div>

                <div className="form-check mb-4">
                    <input className="form-check-input" type="checkbox" id='consent' name="consent" onChange={this.handleChange} style={{ marginTop: '7px'}} />
                    <p style={{ textAlign: 'left', paddingTop: '5px', fontSize: 'small', marginBottom: '15px', marginTop: '20px' }}>I agree to the terms and conditions. </p>
                    <p className='error_msg'>{this.state.errors.consent}</p>
                </div>

                <button className="btn btn-primary btn-block w-100" onClick={this.submitRequest}>Create ABHA Card</button>
            </>
        )
    }
}