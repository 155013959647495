import React, { Component } from 'react';
import { Navigate } from 'react-router-dom';

// Elements
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col';

// Steps
import ABDMLoading from './Loader';
import ABDMAdhaarEnrollment from './AadhaarEnrollment'
import ABDMVerifyAadhaarOTP from './VerifyAadhaarOTP';
import ABDMMobileVerification from './MobileVerification';
import ABDMAbhaAddressUpdate from './AbhaAddressUpdate';
import ABDMDownloadAbhaCard from './DownloadAbhaCard';

export default class ABDMEnrollmentFormHome extends Component {
    state = {
        step: 1,
        req_id: '',
        message: {},
        error_message: {},
        shared_states: {}
    };

    updateStep = (step, message='', error_message='', shared_states={}) => {
        this.setState({
            step: step,
            message: {
                ...this.state.message,
                [step]: message  // Dynamically updating messageMap
            },
            error_message: {
                ...this.state.error_message,
                [step]: error_message  // Dynamically updating errorMessageMap
            },
            shared_states: {
                ...this.state.shared_states,
                [step]: shared_states
            }
        });
    }

    updateReqId = (req_id) => {
        this.setState({
            req_id: req_id
        });
    }

    getReqId = () => {
        return this.state.req_id;
    }

    render() {
        if(!(this.props.clinic.abdm && this.props.clinic.abdm.enabled)) {
            return <Navigate to="/not-found" />;
        }
        return (
            <Container fluid>
                <Row>
                    <Col lg={12} md={12} sm={12} xs={12} className='page-header'>
                        <h1 className='page-title'>Create ABHA Card (Health ID)</h1>
                    </Col>
                    <Col lg={{ span: 6, offset: 3 }} md={{ span: 10, offset: 1 }} sm={{ span: 10, offset: 1 }} xs={12}>
                        <div className="service-block service-block-nohover">
                            {this.state.step === 0 && <ABDMLoading currentStep={this.state.step}/>}
                            {this.state.step === 1 && <ABDMAdhaarEnrollment currentStep={this.state.step} updateStep={this.updateStep}
                                                                            updateReqId={this.updateReqId}
                                                                            message={this.state.message}
                                                                            error_message={this.state.error_message}
                                                                            shared_states={this.state.shared_states}  />}
                            {this.state.step === 2 && <ABDMVerifyAadhaarOTP currentStep={this.state.step} updateStep={this.updateStep}
                                                                            getReqId={this.getReqId} message={this.state.message}
                                                                            error_message={this.state.error_message}
                                                                            shared_states={this.state.shared_states}/>}
                            {this.state.step === 3 && <ABDMMobileVerification currentStep={this.state.step} updateStep={this.updateStep}
                                                                            getReqId={this.getReqId} message={this.state.message}
                                                                            error_message={this.state.error_message}
                                                                            shared_states={this.state.shared_states}/>}
                            {this.state.step === 4 && <ABDMAbhaAddressUpdate currentStep={this.state.step} updateStep={this.updateStep}
                                                                            getReqId={this.getReqId} message={this.state.message}
                                                                            error_message={this.state.error_message}
                                                                            shared_states={this.state.shared_states}/>}
                            {this.state.step === 5 && <ABDMDownloadAbhaCard currentStep={this.state.step} updateStep={this.updateStep}
                                                                            getReqId={this.getReqId} message={this.state.message}
                                                                            error_message={this.state.error_message}
                                                                            shared_states={this.state.shared_states}/>}
                            <div className='mt-5'>
                                <p style={{fontSize:"12px"}}><strong>APPROVED BY NHA</strong> <img src='/img/nha.png'/></p>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }
}