import React, { Component } from 'react';
import {get_request_option} from '../Helper/helper'

export default class ABDMEnrollemtStepBase extends Component {

    hasAnyError = () => {
        for( const key in this.state.errors) {
            let value = this.state.errors[key];
            if(value.length > 0) {
                return true
            }
        }
        return false;
    }

    validateInput = () => {
        // override in child class
        return true
    }

    handleResponse = (response) => {
        // override in child class
    }

    getEndpointUrl = () => {
        // override in child class
        return ""
    }

    getRequestData = () => {
        // override in child class
        return {}
    }

    getComponentStep = () => {
        // override in child class
        return 0
    }


    submitRequest = (e) => {
        e.preventDefault();
        this.validateInput();
        this.forceUpdate();
        if(this.hasAnyError()) {
            return
        }

        // show loader
        this.props.updateStep(0);

        // send request
        let requestData = JSON.stringify(this.getRequestData());
        fetch(this.getEndpointUrl(), get_request_option("POST", requestData))
        .then(response => response.json())
        .then(data => {
            this.handleResponse(data);
        }).catch( err => {
            console.log(err);
        });
    }

    render_internal = () => {
        // override this in base clasee
        return <></>
    }

    render() {
        if(this.props.currentStep != this.getComponentStep()) {
            return <></>
        }
        return this.render_internal()
    }
}