import React from 'react';
import ABDMEnrollemtStepBase from './EnrollmentStepBase'
import {Mode} from '../Helper/constants'
import {get_request_option} from '../Helper/helper'


// debug responses
import * as abdm_request_otp_success from '../../testdata/abdm_request_otp_success.json';

export default class ABDMMobileVerification extends ABDMEnrollemtStepBase {

    constructor(props) {
        super(props);
        this.syncMessage();
        this.startOTPTimer();
        this._isMounted = false
    }

    state = {
        data : {
            otp: ''
        },
        message: '',
        error_message: '',
        errors: {},
        otp_resend_timer: 0,
        show_resend_button: false,
        otp_timer: 0,
        otp_request_in_progress : false
    }

    syncMessage = () => {
        this.state = {
            ...this.state,
            message: this.props.message[this.getComponentStep()] || "",
            error_message: this.props.error_message[this.getComponentStep()] || "",
        };
    }

    validateInput = () => {
        this.state.errors.otp = this.state.data.otp.length !== 6 ? 'Please enter 6 digit OTP' : '';
    }

    handleResponse = (response) => {
        if(response.status === 200) {
            this.props.updateStep(4, response.message, '', response.suggestions);
        } else if(response.status === 500) {
            this.props.updateStep(this.getComponentStep(), '', response.message)
        }
    }

    getEndpointUrl = () => {
        return '/dashboard/abdm/enroll/link_mobile'
    }

    getRequestData = () => {
        let data = this.state.data;
        data.req_id = this.props.getReqId()
        return data
    }

    getComponentStep = () => {
        return 3
    }

    formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    handleChange = (e) => {
        const target = e.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        let name = target.name;
        this.setState({
            data:{
                ...this.state.data,
                [name]: value
            }
        });
    }

    startOTPTimer = () => {
        this.state.otp_resend_timer = 120;
        this.state.show_resend_button = false;
        this.state.otp_timer = setInterval(() => {
            if(!this._isMounted) return;
            this.setState({
                otp_resend_timer: this.state.otp_resend_timer - 1
            })
            if(this.state.otp_resend_timer === 0) {
                clearInterval(this.state.otp_timer);
                this.setState({
                    show_resend_button: true,
                    otp_timer: 0
                })
            }
        }, 1000)
    }

    componentDidMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        if (this.state.otp_timer) {
            clearInterval(this.state.otp_timer); // Clear the timer when unmounting
        }
        this._isMounted = false;
    }

    handleResendOTPRequest = (response) => {
        // TODO
        if(response.status === 200) {
            this.state.message = response.message;
            this.state.error_message = ''
            this.startOTPTimer()
        } else if(response.status === 500) {
            this.state.error_message = response.message;
            this.state.message = ''
        }
        this.setState({
            otp_request_in_progress: false
        })
    }

    resendOTP = () => {
        this.setState({
            otp_request_in_progress: true
        })
        // TODO : RESEND MOBILE VERIFICATION OTP
        return
        if(Mode.DEBUG) {
            let data = abdm_request_otp_success.default;
            this.handleResendOTPRequest(data);
        } else {
            let data = this.props.shared_states;
            data.req_id = this.getReqId()
            let requestData = JSON.stringify(data);
            fetch('/dashboard/abdm/enroll/request_otp', get_request_option("POST", requestData))
            .then(response => response.json())
            .then(data => {
                this.handleResendOTPRequest(data);
            }).catch( err => {
                console.log(err);
            });
        }
    }

    render_internal = () => {
        return (
            <>
                <h1 className='mb-3'>Verify Mobile</h1>

                {(this.state.message)?
                <div className='alert alert-success mb-3'>
                    {this.state.message}
                </div>:null}

                {(this.state.error_message)?
                <div className='alert alert-danger mb-3'>
                    {this.state.error_message}
                </div>:null}
                
                <div className="form-group">
                    <label className="control-label sr-only required" htmlFor="otp"></label>
                    <input id="otp" name="otp" type="number" placeholder="Please enter 6 digit OTP" className="form-control" onChange={this.handleChange} max={999999}/>
                    <p className='error_msg'>{this.state.errors.otp}</p>
                </div>

                <div className='mt-3 mb-4'>
                    {(this.state.otp_request_in_progress)?
                        <p className='btn'>Requesting OTP</p>
                    :
                        (this.state.show_resend_button)?
                            <button onClick={this.resendOTP} className='btn btn-light'>Resend OTP</button>
                        :
                            <p className='btn'>Resend in {this.formatTime(this.state.otp_resend_timer)}</p>
                    }
                </div>

                <button className="btn btn-primary btn-block w-100 btn-block" onClick={this.submitRequest}>Submit OTP</button>
            </>
        )
    }
}