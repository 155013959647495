import React, { Component } from 'react';

import ABDMEnrollemtStepBase from './EnrollmentStepBase'

// Elements
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default class ABMLoading extends ABDMEnrollemtStepBase {

    getComponentStep = () => {
        return 0
    }

    render_internal = () => {
        return (
            <Row>
                <Col lg={12} md={12} sm={12} xs={12}>
                    <div className="section-title">
                        <h1>Loading...</h1>
                    </div>
                </Col>
                <Col lg={{ span: 8, offset: 2 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={12}>
                    <div className="spinner-border" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </Col>
            </Row>
        )
    }
}