import React from 'react';
import ABDMEnrollemtStepBase from './EnrollmentStepBase'


// debug responses
import * as abdm_link_mobile_valid_otp from '../../testdata/abdm_link_mobile_valid_otp.json'
import * as abdm_request_otp_success from '../../testdata/abdm_request_otp_success.json';
import * as abdm_link_mobile_invalid_otp from '../../testdata/abdm_link_mobile_invalid_otp.json';

export default class ABDMDownloadAbhaCard extends ABDMEnrollemtStepBase {

    constructor(props) {
        super(props);
        this.syncMessage();
    }

    state = {
        data : {
            abha_address: ''
        },
        message: '',
        error_message: '',
        errors: {}
    }

    syncMessage = () => {
        this.state = {
            ...this.state,
            message: this.props.message[this.getComponentStep()] || "",
            error_message: this.props.error_message[this.getComponentStep()] || "",
        };
    }

    getComponentStep = () => {
        return 5
    }

    render_internal = () => {
        return (
            <>
                <h1 className='mb-3'>Download ABHA Card</h1>

                {(this.state.message)?
                <div className='alert alert-success mb-3'>
                    {this.state.message}
                </div>:null}

                {(this.state.error_message)?
                <div className='alert alert-danger mb-3'>
                    {this.state.error_message}
                </div>:null}
                
                <div className="abha-card mb-4 ms-auto me-auto">
                    <img src={"data:image/jpeg;base64," + this.props.shared_states[this.getComponentStep()].full_name} alt="Profile Picture"/>
                    <div className="abha-card-details">
                    <div className="abha-card-header">ABHA Health Card</div>
                    <div className="abha-card-content">{this.props.shared_states[this.getComponentStep()].full_name}</div>
                    <div className="abha-card-content">{this.props.shared_states[this.getComponentStep()].phrAddress}</div>
                    <div className="abha-card-content">DOB: {this.props.shared_states[this.getComponentStep()].dob}</div>
                    <div className="abha-card-id">ABHA: {this.props.shared_states[this.getComponentStep()].healthId}</div>
                    <div className="abha-card-footer">Issued by National Health Authority</div>
                    </div>
                </div>
            

                <button className="btn btn-primary btn-block w-100 btn-block" onClick={this.submitRequest}>Download ABHA Card</button>
            </>
        )
    }
}